import  {langArr}  from "./langArray";
import axios from "axios";

// -------------- language switcher ---------------------- //

const selectLangEl = document.querySelector('.language-switcher');

let lang = '';
let lsLang = localStorage.getItem("exmoto-lsLang");
if (lsLang) { lang = JSON.parse(localStorage.getItem("exmoto-lsLang")) } else { lang = 'ua' };
selectLangEl.value=lang;
const changeLanguageText = (lang) => {
  for (let key in langArr) {
      let elem = document.querySelectorAll('.lng-' + key);
      if (elem.length) {  
        for ( const item of elem ) {
          item.innerHTML = langArr[key][lang]
        }}
    }
}
changeLanguageText(lang);

selectLangEl.addEventListener('change',(evt) =>{
  lang = evt.currentTarget.value;
  console.log('in Listener',lsLang, lang);
  localStorage.setItem("exmoto-lsLang",JSON.stringify(lang))
  changeLanguageText(lang);
})

// ------------------------ mobile menu ------------------
const mobileMenu = document.querySelector("[data-modal]");
const openMenuBtn = document.querySelector("[data-modal-open]");
const closeMenuBtn = document.querySelector("[data-modal-close]");

  const toggleMenu = () => {
    changeLanguageText(lang);
    hiddensMenuItems();
    const isMenuOpen =
      openMenuBtn.getAttribute('aria-expanded') === 'true' || false;
    openMenuBtn.setAttribute('aria-expanded', !isMenuOpen);
    mobileMenu.classList.toggle('is-hidden');

    const scrollLockMethod = !isMenuOpen
      ? 'disableBodyScroll'
      : 'enableBodyScroll';
    bodyScrollLock[scrollLockMethod](document.body);
  };

  openMenuBtn.addEventListener('click', toggleMenu);
  closeMenuBtn.addEventListener('click', toggleMenu);

  // Close the mobile menu on wider screens if the device orientation changes
  window.matchMedia('(min-width: 768px)').addEventListener('change', e => {
    if (!e.matches) return;
    mobileMenu.classList.remove('is-open');
    openMenuBtn.setAttribute('aria-expanded', false);
    bodyScrollLock.enableBodyScroll(document.body);
  });
// +++++++++++ работа с пунктами мобильного меню (скрыть/показать) +++++++++++//
  const mmOneEl = document.querySelector('#mm-one');
  const mmTwoEl = document.querySelector('#mm-two');
  const mmThreeEl = document.querySelector('#mm-three');
  const mmAllBtnEl = document.querySelector('#mmAll-btn');

  const hiddensMenuItems = () => {
    mmOneEl.classList.add('hiddens');
    mmTwoEl.classList.add('hiddens');
    mmThreeEl.classList.add('hiddens');
  };

  mmAllBtnEl.addEventListener('click', (evt) => {
    hiddensMenuItems();
// ++++++++++ работа с языками укр/рус ++++++++++++++
   const tt = evt.target.dataset.action;
     if (tt) {
     const xx = document.querySelector(`#${tt}`);
     xx.classList.remove('hiddens');
   }
 }); 

 // ++++++++++ выбор квадрата в боковом меню справа +++++++++++++++++++
 const LinkSqrActiveEl = document.querySelector('.page-section');
 //console.log('LinkSqrActiveEl',LinkSqrActiveEl);
 if (LinkSqrActiveEl) {
 switch (LinkSqrActiveEl.dataset.action) {
  case 'srv-3': document.querySelector('#srv-3').classList.add('active');
    break;
  case 'srv-2': document.querySelector('#srv-2').classList.add('active');
    break;  
  case 'srv-4': document.querySelector('#srv-4').classList.add('active');
    break;
  case 'srv-5': document.querySelector('#srv-5').classList.add('active');
    break;  
  case 'srv-6': document.querySelector('#srv-6').classList.add('active');
    break;
  case 'srv-7': document.querySelector('#srv-7').classList.add('active');
    break;
  case 'srv-8': document.querySelector('#srv-8').classList.add('active');
    break;
  case 'srv-9': document.querySelector('#srv-9').classList.add('active');
    break;
  case 'srv-0': document.querySelector('#srv-0').classList.add('active');
    break; 
 }
 }
 // ++++++++++++++++++ ОТСЛЕЖИВАНИЕ СТАТУСА ТТН по НОМЕРУ ТТН +++++++++++++++++++++++++++++++ //
 //console.log('Btn TRACING',ttt);
//  export const trackingTtn = async function (numTtn) {
//   const response = await axios.get(`https://api.exmoto.com/tracking?INwb_no=${numTtn}`);
//   return response;
// };

export const trackingTtn = async function (numTtn) {
const TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRlcnByaXNlIjoiMSIsImVudF9pZCI6MSwiaWF0IjoxNzIxMjA5Mjg3LCJleHAiOjE3NTI3NDUyODd9.NKuIDBS2qKc7f-zG4_rhmb-IUAWNwqat2Ce8-chnOOM';
axios.defaults.headers['Authorization'] = TOKEN;
  const response = await axios.get(`https://exmoto.com:63012/api/v1/web/ttn/?id=${numTtn}`);
  console.log('in axios trackingTtn',response)
  return response;
};

 const modalTracking = document.querySelector("[data-modal-tracking]");
 const openModalTrackBtn = document.querySelector("[data-modal-tracking-open]");
 const closeModalTrackBtn = document.querySelector("[data-tracking-close]");
 const BtnTrackingEl = document.querySelector('.button-header');
 const InpTtnNumEl = document.querySelector('.input__number');
 const trackDataEl = document.querySelector('#trackData');

 //console.log('modalTracking', modalTracking);
 //console.log('closeModalTrackBtn', closeModalTrackBtn);

 closeModalTrackBtn.addEventListener('click',(evt)=>{modalTracking.classList.toggle('is-hidden')})

 let ttt ='';
 let markUp = "";

 BtnTrackingEl.addEventListener('click',(evt) =>{
  trackDataEl.innerHTML= "";
  modalTracking.classList.toggle('is-hidden');
  ttt = evt;
  const responseBtn = trackingTtn(InpTtnNumEl.value); 
  //console.log('Btn TRACING',ttt);
  //console.log(InpTtnNumEl.value);
  console.log(responseBtn
    .then(resp=> {console.log('in promise',resp.data.data[0])
      if (resp.data.data[0]) { 
      markUp=`<li>Номер ТТН: ${resp.data.data[0].wb_no}</li>
              <li>Тип сервісу: ${resp.data.data[0].T_SRV}</li>
              <li>Звідки: ${resp.data.data[0].ORG}</li>
              <li>Куди: ${resp.data.data[0].DEST}</li>
              <li>Прийнято: ${resp.data.data[0].RCPN}</li>
              <li>Розрахункова дата: ${resp.data.data[0].D_Acc}</li>
              <li>Доставлено: ${resp.data.data[0].DTD}</li>`;
      trackDataEl.innerHTML= markUp} else {
        trackDataEl.innerHTML= `<p>Не вірний номер ТТН !!!</p>`
      }
    })
    .catch(error => {console.log(error.message)}));
})

 // ++++++++++++++++++ ВЫЗОВ КУРЬЕРА +++++++++++++++++++++++++++++++ //

//  export const callCourier = async function (name,phone) {
//   console.log('in callCourier AXIOS', name,phone);
//   const response = await axios.post(`https://exmoto.com/neworder`,
//     { contact: `${name}`, 
//       phone: `${phone}`,
//       page_num: 1,
//       page_count: 1,
//       finished: 0,
//       form_build_id: 'form-poXll5_jb8fdW1f6BS2AgxS2kxSqOTJ9PZqmx5LrL7g',
//       form_id: 'webform_client_form_356'
//     }
//   );
//   return response;
// };

export const callCourier = async function (name,phone) {
  const TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRlcnByaXNlIjoiMSIsImVudF9pZCI6MSwiaWF0IjoxNzIxMjA5Mjg3LCJleHAiOjE3NTI3NDUyODd9.NKuIDBS2qKc7f-zG4_rhmb-IUAWNwqat2Ce8-chnOOM';
  axios.defaults.headers['Authorization'] = TOKEN;
    //console.log('in callCourier AXIOS', name,phone);
    const response = await axios.post(`https://exmoto.com:63012/api/v1/order/ordr1`,
      {data:{ fio: `${name}`, 
        phone: `${phone}`
      }}
    );
    return response;
  };
 //const BtnCallCourierEl = document.querySelector('.button-courier');
 const WebFormCallCourierEl = document.querySelector('.form-call-courier');
 //console.log('BTN CALL COURIER', BtnCallCourierEl);
 //console.log('WEB FORM CALL COURIER', WebFormCallCourierEl);
 WebFormCallCourierEl.addEventListener('submit',(evt)=>{
  evt.preventDefault()
  //console.log('INPUTS WEB FORM ONE', evt.target.elements.name.value)
  //console.log('INPUTS WEB FORM TWO', evt.target.elements.phone.value)
  const responseCallCourier = callCourier(evt.target.elements.name.value,evt.target.elements.phone.value);
  responseCallCourier.then(resp => { console.log('in promise',resp) 
                                      WebFormCallCourierEl.innerHTML=`<p>Ваше замовлення прийнято!</p>`
                                    })
                     .catch(error => {console.log(error.message)})
 }, { once: true })

